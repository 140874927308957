<template>
    <v-icon v-if="hasAbstained" x-large color="teal darken-2">{{ votingStates.abstained }}</v-icon>
    <span v-else>{{ value }}</span>
</template>

<script>
    import Vue from "vue";
    import { NumericVotingStates } from "./VotingStates";

    export default Vue.extend({
        name: "NumericVote",

        props: {
            value: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                votingStates: new NumericVotingStates(),
            };
        },

        computed: {
            hasAbstained() {
                return this.value === "-";
            },
        },
    });
</script>

<style scoped>
span {
    font-size: 40px;
}
</style>