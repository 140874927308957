<template>
    <v-row align="center" justify="center">
        <v-col class="text-left" :cols="8">
            <h1>About Planning Poker</h1>
            <p>
                The Planning Poker application was created to facilitate planning poker sessions in remote
                agile (scrum) teams.
            </p>
            <p>
                The tool was created by:
                <ul>
                    <li>Tobias Mattsson</li>
                    <li>Karl Zeift</li>
                </ul>
            </p>

            <p id="build-data">
            </p>

        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'About',

        mounted() {
            document.querySelector('#build-data').innerHTML =
            `Build timestamp: ${document.documentElement.dataset.buildTimestampUtc}`;
        },
    };
</script>
