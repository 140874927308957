<template>
    <v-row align="center" justify="center">
        <v-col class="text-center">
            <v-text-field label="Server URL" v-model="serverUrl"></v-text-field>
            <v-btn class="ma-2" color="primary" @click="onClickSave">Save</v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import { VotingServiceInstance } from "../services/VotingService";

    export default {
        name: 'Settings',

        data() {
            return {
                serverUrl: "",
                VotingServiceInstance: VotingServiceInstance,
            }
        },
        methods: {
            onClickSave() {
                this.VotingServiceInstance.reconnect(this.serverUrl);
            }
        },
    };
</script>
