export class VotingStates {
  constructor() {
      this.questionMark = 'mdi-comment-question'; // vote casted, waiting for others.
      this.hourGlass = 'mdi-timer-sand';
  }
}

export class ConsentVotingStates extends VotingStates {
  constructor() {
      super();

      this.thumbsUp = 'mdi-thumb-up';
      this.gift = 'mdi-gift';
      this.envelope = 'mdi-email';
  }
}

export class NumericVotingStates extends VotingStates {
  constructor() {
      super();

      this.abstained = 'mdi-chat-remove-outline';
  }
}
