<template>
    <v-icon x-large color="teal darken-2">{{ icon }}</v-icon>
</template>

<script>
    import Vue from "vue";
    import { ConsentVotingStates } from "./VotingStates";

    export default Vue.extend({
        name: "ConsentVote",

        props: {
            value: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                votingStates: new ConsentVotingStates(),
            };
        },

        computed: {
            icon() {
                switch (this.value) {
                    case "approval":
                        return this.votingStates.thumbsUp;
                    case "objection":
                        return this.votingStates.gift;
                    case "concern":
                        return this.votingStates.envelope;
                    default:
                        console.log(`Unknown vote: ${this.value}.`);
                        return this.votingStates.questionMark;
                }
            },
        },
    });
</script>
