import EventBus from "./EventBus";
import ParamProcesson from "./ParamProcessor";

export class VotingService {

    //serverUrl = "not configured";

    constructor() {
        this.reconnect();
    }

    reconnect(serverUrl = process.env.VUE_APP_SERVER_URL) {
        this.sessionID = "";
        this.firstname = "";
        this.paramProcessor = new ParamProcesson();
        this.socket = new WebSocket(`${serverUrl}`);

        /*
        this.socket.onopen = () => {
            this.socket.send("Communication link established.");
        };
        */

        this.socket.onmessage = this.messageHandler.bind(this);

        this.socket.onclose = (closeEvent) => {
            console.log(`closedEvent: ${closeEvent.code}`);
        }

        this.socket.onerror = () => {
            console.log("Communication error.");
        };
    }

    messageHandler(event) {
        console.log(`Event: ${event.data}`);

        const incoming = event.data.split(":");
        const command = incoming[0];
        let params = null;

        switch (command) {
            case "session":
                params = this.paramProcessor.parseSession(incoming);
                this.sessionID = params.sessionID;
                this.sessionType = params.type;
                EventBus.$emit('sessionCreated', { id: this.sessionID, type: this.sessionType });
                break;
            case "joined":
                params = this.paramProcessor.parseJoined(incoming);
                this.joinedSession(params.firstname);
                break;
            case "voted":
                params = this.paramProcessor.parseVoted(incoming);
                this.voted(params.firstname, params.vote);
                break;
            case "voteReset":
                EventBus.$emit('voteReset');
                break;
            case "left":
                params = this.paramProcessor.parseLeft(incoming);
                this.left(params.firstname);
                break;
            case "reveal":
                EventBus.$emit('reveal');
                break;
            default:
                console.log("Unknown command!");
        }
    }

    async newSession(type) {
        this.socket.send(`newSession:${type}`);
    }

    joinedSession(joinedsName) {
        // This user joined the session, enable voting.
        if (this.firstname === joinedsName) {
            EventBus.$emit('thisVoterJoined');
        }

        EventBus.$emit('voterJoined', joinedsName);
    }

    joinSession(firstname, sessionId) {
        if (!this.sessionID) {
            this.sessionID = sessionId;
        }
        this.firstname = firstname;
        console.log(`joinSession:${this.sessionID}:${firstname}`);
        this.socket.send(`joinSession:${this.sessionID}:${firstname}`)
    }

    doVote(theVote) {
        this.socket.send(`vote:${this.sessionID}:${this.firstname}:${theVote}`);
    }

    resetSession() {
        this.socket.send(`resetVote:${this.sessionID}`);
    }

    requestReveal() {
        this.socket.send(`reveal`);
    }

    voted(firstname, vote) {
        EventBus.$emit('voted', firstname, vote);
    }

    left(firstname) {
        EventBus.$emit('left', firstname);
    }
}

export let VotingServiceInstance = new VotingService();
