<template>
    <v-container>
        <div v-if="!sessionStarted">
            <v-form @submit.prevent="onSubmitJoinSession">
                <v-row>
                    <v-col xs="12" >
                        <v-text-field label="Session ID" v-model="sessionID" inputmode="numeric" :autofocus="!isSessionIdPreEntered"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" >
                        <v-text-field label="Firstname" v-model="firstname" :autofocus="isSessionIdPreEntered"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" >
                        <v-btn type="submit" class="ma-2">Join</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div v-show="sessionStarted">
            <div class="text-center">
                <h2>{{ firstname }}</h2>
                <p>{{ sessionID }}</p>
                <consent-vote-buttons v-if="isConsentSession" :disable-voting-buttons="disableVotingButtons" @vote="onVote" />
                <planning-poker-buttons v-else :disable-voting-buttons="disableVotingButtons" @vote="onVote" />
            </div>

            <v-divider class="ma-4"></v-divider>

            <vote-results :type="sessionType" />

            <div v-if="sessionStarter">
                <v-divider class="ma-4"></v-divider>
                <div class="text-center">
                    <v-btn class="ma-2" color="primary" @click="onResetClick">Reset session</v-btn>
                    <v-btn class="ma-2" color="primary" @click="onRevealClick">Force Reveal Votes</v-btn>
                </div>
            </div>

        </div>
    </v-container>
</template>

<script>
    import Vue from "vue";
    import { VotingServiceInstance as VotingService } from "../services/VotingService";
    import EventBus from "../services/EventBus";
    import ConsentVoteButtons from "./ConsentVoteButtons";
    import PlanningPokerButtons from "./PlanningPokerButtons";
    import VoteResults from "./VoteResults";

    export default Vue.extend({
        name: 'JoinSession',

        components: { ConsentVoteButtons, PlanningPokerButtons, VoteResults },
        data() {
            return {
                firstname: "",
                sessionID: this.$router.currentRoute.query.sessionID,
                sessionStarted: false,
                sessionStarter: this.$router.currentRoute.params.sessionStarter,
                disableVotingButtons: false,
                sessionType: "consent",
            };
        },
        computed: {
            isSessionIdPreEntered() {
                return Boolean(this.$router.currentRoute.query.sessionID);
            },
            isConsentSession() {
                return this.sessionType === "consent";
            },
        },
        methods: {
            onSubmitJoinSession() {
                this.sessionId = VotingService.joinSession(this.firstname, this.sessionID);
            },
            onRevealClick() {
                if (!confirm("This will reveal the vote results before the vote is finished. Are you sure?")) {
                    return;
                }

                VotingService.requestReveal();
            },
            onSessionCreated({ type }) {
                this.sessionType = type;
            },
            onJoinSession() {
                this.sessionStarted = true;
            },
            onVoteReset() {
                this.disableVotingButtons = false;
            },
            onVote(theVote) {
                VotingService.doVote(theVote);
                this.disableVotingButtons = true;
            },
            onResetClick() {
                VotingService.resetSession();
            },
        },
        mounted() {
            EventBus.$on("sessionCreated", this.onSessionCreated);
            EventBus.$on("thisVoterJoined", this.onJoinSession);
            EventBus.$on("voteReset", this.onVoteReset);
        },
        beforeDestroy() {
            EventBus.$off("sessionCreated", this.onSessionCreated);
            EventBus.$off("thisVoterJoined", this.onJoinSession);
            EventBus.$off("voteReset", this.onVoteReset);
        }
    });
</script>
