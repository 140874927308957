<template>
    <div>
        <v-btn fab x-large color="primary" @click="onVoteClick('-')" :disabled="disableVotingButtons" class="ma-2">
            <v-icon>mdi-chat-remove-outline</v-icon>
        </v-btn>
        <v-btn v-for="voteOption in voteOptions" :key="voteOption" fab x-large color="primary" @click="onVoteClick(voteOption)" :disabled="disableVotingButtons" class="ma-2">
            {{ voteOption }}
        </v-btn>
    </div>
</template>

<script>
    import Vue from "vue";

    export default Vue.extend({
        name: 'PlanningPokerButtons',

        props: {
            disableVotingButtons: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            voteOptions() {
                return [0, 0.5, 1, 2, 3, 5, 8, 13, 20, 40, 100];
            },
        },

        methods: {
            onVoteClick(vote) {
                this.$emit("vote", vote);
            },
        },
    });
</script>