<template>
    <v-app id="planning-poker-client">
        <v-navigation-drawer v-model="drawer" app>
            <v-list dense>
                <v-list-item link href="/#/newSession">
                    <v-list-item-action>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link href="/#/settings">
                    <v-list-item-action>
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link href="/#/howToUse">
                    <v-list-item-action>
                        <v-icon>mdi-text-box-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>How to use</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link href="/#/about">
                    <v-list-item-action>
                        <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>About</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app color="green" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            <v-toolbar-title>Planning Poker</v-toolbar-title>
        </v-app-bar>

        <v-main>
            <v-container class="fill-height" fluid>

                <router-view></router-view>

            </v-container>
        </v-main>
        <v-footer color="green" app>
            <span class="white--text">Planning poker for agile dev-teams</span>
        </v-footer>
    </v-app>
</template>

<script>

    export default {
        props: {
            source: String,
        },
        methods: {
            onHomeClick() {
                this.$router.push({
                    path: "/home",
                });
            }
        },
        data: () => ({
            drawer: null,
        }),
    }
</script>
