class ParamProcesson {

    parseSession(incomingCommand) {
        return { sessionID: incomingCommand[1], type: incomingCommand[2] };
    }

    parseJoined(incomingCommand) {
        return { firstname: incomingCommand[2] };
    }

    parseVoted(incomingCommand) {
        return { firstname: incomingCommand[2], vote: incomingCommand[3] };
    }

    parseLeft(incomingCommand) {
        return { firstname: incomingCommand[2] };
    }
}

export default ParamProcesson;
