<template>
    <v-row align="center" justify="center">
        <v-col class="text-center">
            <v-btn class="ma-2" @click="onClickNewPlanningPokerSession">New Session</v-btn>
            <v-btn class="ma-2" @click="onClickJoinSession">Join Session</v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import { VotingServiceInstance as VotingService } from "../services/VotingService";
    import EventBus from "../services/EventBus";

    export default {
        name: 'Start',

        data: () => ({
            drawer: null,
        }),
        methods: {
            onClickNewPlanningPokerSession() {
                VotingService.newSession('numeric');
            },
            onClickJoinSession() {
                this.$router.push({
                    path: "/joinSession",
                });
            },
            onSessionCreated({ id }) {
                this.sessionId = id;
                this.$router.push({
                    name: "joinSession",
                    query: {sessionID: `${this.sessionId}`},
                    params: { sessionStarter: "true" },
                });
            }
        },
        mounted() {
            EventBus.$on("sessionCreated", this.onSessionCreated);
        },
        beforeDestroy() {
            EventBus.$off("sessionCreated", this.onSessionCreated);
        }

    };
</script>
