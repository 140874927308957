<template>
    <div>
        <v-btn fab x-large color="primary" @click="onVoteClick('approval')" :disabled="disableVotingButtons" class="ma-2">
            <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
        <v-btn fab x-large color="primary" @click="onVoteClick('objection')" :disabled="disableVotingButtons" class="ma-2">
            <v-icon>mdi-gift</v-icon>
        </v-btn>
        <v-btn fab x-large color="primary" @click="onVoteClick('concern')" :disabled="disableVotingButtons" class="ma-2">
            <v-icon>mdi-email</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import Vue from "vue";

    export default Vue.extend({
        name: "ConsentVoteButtons",

        props: {
            disableVotingButtons: {
                type: Boolean,
                required: true,
            },
        },

        methods: {
            onVoteClick(vote) {
                this.$emit("vote", vote);
            },
        },
    });
</script>