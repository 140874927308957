<template>
    <v-row align="center" justify="center">
        <v-col class="text-left" :cols="8">
            <h1>How to use</h1>
            <p>
                This is a guide of how to use Planning Poker.
            </p>
            <h2>Starting a new session</h2>
            <p>
                Start a new session by clicking the button "New Session". You will get a 
                session id and you have to enter your name. Then click "join"-button. 
                The session is now started and you are the first participant.
            </p>
            <h2>Inviting others</h2>
            <p>
                Now, copy the browser url, that looks something like this <code>https://planning-poker.zeift.com/#/joinSession?sessionID=9469</code>
                Share the url in a chat, for exampel Skype for Business.
            </p>

            <h2>Participants joining</h2>
            <p>
                Each participant clicks on the link, and enters his/hers 
                name in the form and clicks join.
            </p>
            <h2>Voting</h2>
            <p>
                Each participant clicks on the number he/she votes for. Once all Participants
                have voted, the results are revealed.
            </p>
            <h2>If someones has to leave</h2>
            <p>
                If a participant needs to leave, or gets disconnected etc, the person started 
                the session might need to use the button "force reveal votes". 
            </p>

        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'HowToUse',

        mounted() {
        },
    };
</script>
