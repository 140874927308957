<template>
    <div>
        <v-card class="d-inline-block ma-2" v-for="(voter, firstname) in voters" :key="firstname">
            <v-list-item>
                <v-list-item-content>
                    <div class="overline mb-4">RÖST</div>
                <div class="text--primary">{{ voter.firstname }}</div>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                    <vote :type="type" :value="voter.vote" :reveal="isRevealed" />
                </v-list-item-avatar>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
    import Vue from "vue";
    import EventBus from "../services/EventBus";
    import { Vote } from "./Vote";

    export default Vue.extend({
        name: "VoteResults",

        components: { Vote },

        props: {
            type: {
                type: String,
                required: true
            },
        },

        data() {
            return {
                voters: [],
                forceReveal: false,
            };
        },

        computed: {
            isRevealed() {
                return this.forceReveal || this.allHaveVoted;
            },
            allHaveVoted() {
                return this.voters.every((aVoter) =>
                    aVoter.vote !== ""
                );
            },
        },

        methods: {
            removeVoter(firstname) {
                const i = this.voters.findIndex((aVoter) =>
                    aVoter.firstname === firstname
                );
                this.voters.splice(i, 1);
            },
            onVoterJoined(firstname) {
                const voter = new Voter(firstname);
                voter.vote = "";
                this.voters.push(voter);
            },
            onVoted(firstname, vote) {
                const voter = this.voters.find((aVoter) =>
                    aVoter.firstname === firstname
                );

                if (voter) {
                    voter.vote = vote;
                } else {
                    console.log(`incoming vote from non-existing voter (${firstname}). do nothing.`);
                }
            },
            onVoteReset() {
                console.log('resetSession');
                this.voters.forEach((aVoter) => {
                   aVoter.vote = "";
                });
                this.forceReveal = false;
            },
            onLeft(firstname) {
                console.log(firstname + " is leaving bye bye");
                this.removeVoter(firstname);
            },
            onReveal() {
                console.log("Force revealing votes.");
                this.forceReveal = true;
            },
        },
        mounted() {
            EventBus.$on("voterJoined", this.onVoterJoined);
            EventBus.$on("voted", this.onVoted);
            EventBus.$on("voteReset", this.onVoteReset);
            EventBus.$on("left", this.onLeft);
            EventBus.$on("reveal", this.onReveal);
        },
        beforeDestroy() {
            EventBus.$off("voterJoined", this.onVoterJoined);
            EventBus.$off("voted", this.onVoted);
            EventBus.$off("voteReset", this.onVoteReset);
            EventBus.$off("left", this.onLeft);
            EventBus.$off("reveal", this.onReveal);
        },
    });

    const Voter = class {
        constructor(firstname) {
            this.firstname = firstname ? firstname : "anonymous";
        }
    }
</script>