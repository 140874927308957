import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import JoinSession from "./components/JoinSession";
import Start from "./components/Start";
import Settings from "./components/Settings";
import HowToUse from "./components/HowToUse";
import About from "./components/About";

Vue.config.productionTip = false;

Vue.use(VueRouter);

// https://router.vuejs.org/guide/#html

const routes = [
  {path: '/start', component: Start},
  {path: '/joinSession', name: "joinSession", component: JoinSession},
  {path: '/settings', component: Settings},
  {path: '/howToUse', component: HowToUse},
  {path: '/about', component: About},
  {path: '*', redirect: '/start'}
];

const router = new VueRouter({routes});


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
