<template>
    <v-icon v-if="!hasVoted" x-large color="teal darken-2">{{ votingStates.hourGlass }}</v-icon>
    <v-icon v-else-if="!reveal" x-large color="teal darken-2">{{ votingStates.questionMark }}</v-icon>
    <v-component v-else :is="voteType" :value="value" />
</template>

<script>
    import Vue from "vue";
    import { VotingStates } from "./VotingStates";
    import ConsentVote from "./ConsentVote";
    import NumericVote from "./NumericVote";

    export default Vue.extend({
        name: "Vote",

        components: { ConsentVote, NumericVote },

        props: {
            value: {
                type: String,
                required: true,
            },
            reveal: {
                type: Boolean,
                required: true,
            },
            type: {
                type: String,
                required: true,
                validator: (value) => {
                    return ["consent", "numeric"].includes(value);
                },
            },
        },

        data() {
            return {
                votingStates: new VotingStates(),
            };
        },

        computed: {
            voteType() {
                return this.type === "numeric" ? NumericVote : ConsentVote;
            },
            hasVoted() {
                return this.value.length > 0;
            },
        },
    });
</script>
